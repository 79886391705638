import React from "react"
import Global from "../components/body/pages/global"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/global/"}
      title="Kuda | The Money App for Africans | Open an Account in Minutes"
      description="Kuda, the money app for Africans licensed by the CBN. Zero maintenance fees, free transfers, automatic savings & investments. Join Kuda today!"
    />
    <Global />
  </Layout>
)

export default IndexPage
